import React, { useEffect, useState } from 'react';
import upload from '../../assets/img/upload.png'; // Certifique-se de que o caminho está correto
import './styles.css';

export default function FileUpload({title, setImg, img, multiple=true}: any){
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [previewImg, setPreviewImg] = useState<any>(null);

  useEffect(() => {
    if(fileNames.length == 0)
      setPreviewImg(img)
  }, [img])

  function handleFiles(files: FileList | null) {
    if (files && files.length > 0) {
      const fileList = Array.from(files);
      
      setFileNames(fileList.map(file => file.name));
      setImg(fileList); 
      const previewUrl = URL.createObjectURL(fileList[0]);
      setPreviewImg(previewUrl);
    } 
    else {
      setFileNames([]);
      setImg([]);
      setPreviewImg(null);
    }
  }
  
  function onImageChange(event: any) {
    handleFiles(event.target.files);
  }


  return (
    <div className="parent">
      <div className='file-upload'>
        <img
          className={previewImg ? 'LoadImg' : 'LogoImg'} 
          src={previewImg ? previewImg : upload}
          alt="Preview"
          style={{maxWidth: '100%', maxHeight: '200px'}}
        />

        <p>{title}</p>

        <div style={{width: '100%'}}>
          <input 
            type="file" 
            accept="image/*"
            onChange={onImageChange}
            multiple={multiple}
          />
        </div>

        {fileNames.map((fileName, index) => (
          <p key={index} className="image-name-preview">Nome da Imagem: {fileName}</p>
        ))}
      </div>
    </div>
  );
}
