const lang: any = {
    title: {
        PT: 'Política de Privacidade',
        US: 'Privacy Policy',
        ES: 'Política de Privacidad'
    },

    text: {
        PT: `O aplicativo IntegrAtivos assume o compromisso de respeitar a privacidade dos usuários, coletando apenas dados essenciais os quais não são transmitidos de forma alguma a plataformas e serviços externos fora do domínio da empresa
        possuidora deste aplicativo.

        Para o correto funcionamento do aplicativo, é necessário a coleta da localização. É pedido ao usuário que aceite compartilha-la de alguma maneira para fins exclusivos de localização do dispositivo dentro do mapa integrado ao aplicativo de forma a facilitar o seu uso. Além disso, é também requerido o acesso à câmera. Todas as fotos retiradas possuem o exclusivo intuito de contribuir com o propósito do aplicativo, sendo armazenadas somente para uso das ferramentas e serviços em domínio da presente empresa.
        <br/>
        Todo o tráfego de dados via rede é criptografado.
        <br/>
        Em caso de dúvidas sobre as políticas de privacidade ou termos de uso do aplicativo, por favor, entre em contato através do e-mail: grva@lamce.coppe.ufrj.br 
        `,
        US: `The IntegrAtivos app is committed to respecting user's privacy, collecting only essential data, which is never shared with external platforms and services outside the domain of the company owning this app.

        For the proper functioning of the app, it is necessary to collect location data. Users are asked to agree to share their location exclusively for positioning the device on the app's integrated map to facilitate its use. In addition, access to the camera is also required. All photos taken are intended exclusively to contribute to the app's purpose and are stored only for use by the tools and services under the company's domain.
        <br/>
        All network data traffic is encrypted.
        <br/>
        If you have any questions regarding the privacy policies or terms of use of the app, please contact us via email: grva@lamce.coppe.ufrj.br
        `,
        ES: `La aplicación IntegrAtivos se compromete a respetar la privacidad de los usuarios, recopilando solo los datos esenciales que no se comparten de ninguna manera con plataformas y servicios externos fuera del dominio de la empresa propietaria de esta aplicación.

        Para el correcto funcionamiento de la aplicación, es necesario recopilar datos de ubicación. Se solicita a los usuarios que acepten compartir su ubicación exclusivamente para localizar el dispositivo en el mapa integrado de la aplicación para facilitar su uso. Además, también se requiere acceso a la cámara. Todas las fotos tomadas tienen el exclusivo propósito de contribuir al propósito de la aplicación y se almacenan solo para el uso de las herramientas y servicios bajo el dominio de la empresa.
        <br/>
        Todo el tráfico de datos de red está encriptado.
        <br/>
        Si tiene alguna pregunta sobre las políticas de privacidad o los términos de uso de la aplicación, póngase en contacto con nosotros por correo electrónico: grva@lamce.coppe.ufrj.br
        `
    }
}

export default lang