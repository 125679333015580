const lang: any = {
    export: {
        PT: 'Exportar',
        US: 'Export',
        ES: 'Exportar'
    },

    overview: {
        PT: 'Visão Geral',
        US: 'Overview',
        ES: 'Visión General'
    },

    relatory: {
        PT: 'Relatório',
        US: 'Relatory',
        ES: 'Informe'
    },

    photos: {
        PT: 'Fotos',
        US: 'Photos',
        ES: 'Fotos'
    },

    createdOn: {
        PT: 'Criado em',
        US: 'Created On',
        ES: 'Creado En'
    },

    deadline: {
        PT: 'Prazo',
        US: 'Deadline',
        ES: 'Plazo'
    },

    startDate: {
        PT: 'Data de início',
        US: 'Start date',
        ES: 'Fecha de inicio'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    inspectorName: {
        PT: 'Nome do Inspetor',
        US: 'Inspector Name',
        ES: 'Nombre del Inspector'
    },

    equipment: {
        PT: 'Equipamento',
        US: 'Equipment',
        ES: 'Equipo'
    },

    lastInspection: {
        PT: 'Última Inspeção',
        US: 'Last Inspection',
        ES: 'Última Inspección'
    },

    equipmentName: {
        PT: 'Nome do Equipamento',
        US: 'Equipment Name',
        ES: 'Nombre del Equipo'
    },

    observations: {
        PT: "Observações",
        US: "Observations",
        ES: "Observaciones"
    },

    RepairData: {
        PT: "Dados de Reparo",
        US: "Repair Data",
        ES: "Datos de Reparación"
    },

    BasicData: {
        PT: "Dados Básicos",
        US: "Basic Data",
        ES: "Datos Básicos"
    },
    
    Recommendations: {
        PT: "Recomendações",
        US: "Recommendations",
        ES: "Recomendaciones"
    },

    CoatingEvaluation: {
        PT: "Avaliação de Revestimento",
        US: "Coating Evaluation",
        ES: "Evaluación de Revestimiento"
    },

    SubstrateEvaluation: {
        PT: "Avaliação de Substrato",
        US: "Substrate Evaluation",
        ES: "Evaluación de Sustrato"
    },
    
    SectorFootage: {
        PT: "Metragem por Setor",
        US: "Sector Footage",
        ES: "Metraje por Sector"
    },

    //--------- RepairData
    repairPercent: {
        PT: "% Reparo",
        US: "% Repair",
        ES: "% Reparación"
    },

    repair: {
        PT: "Reparo",
        US: "Repair",
        ES: "Reparación"
    },

    meterage: {
        PT: "Metragem",
        US: "Meterage",
        ES: "Medición"
    },

    totalMeterage: {
        PT: "Metragem Total",
        US: "Total Meterage",
        ES: "Medición Total"
    },
    //--------- End RepairData

    //--------- Recommendations
    points: {
        PT: 'Pontos Avaliados',
        US: 'Evaluated Points',
        ES: 'Puntos Evaluados'
    },

    time: {
        PT: "Tempo",
        US: "Time",
        ES: "Tiempo"
    },

    repairType: {
        PT: "Tipo de Reparo",
        US: "Repair Type",
        ES: "Tipo de Reparación"
    },

    access: {
        PT: "Acesso",
        US: "Access",
        ES: "Acceso"
    },

    repairHeight: {
        PT: "Altura do Reparo",
        US: "Repair Height",
        ES: "Altura de la Reparación"
    },
    //--------- End Recommendations
    
    //--------- Basics
    substrate: {
        PT: 'Substrato',
        US: 'Substrate',
        ES: 'Sustrato'
    },

    coating: {
        PT: 'Revestimento',
        US: 'Coating',
        ES: 'Revestimiento'
    },
    
    temperature: {
        PT: 'Temperatura de Operação',
        US: 'Operation Temperature',
        ES: 'Temperatura de Operación'
    },

    insulation: {
        PT: 'Isolamento Térmico',
        US: 'Thermal Insulation',
        ES: 'Aislamiento Térmico'
    },

    PFP: {
        PT: 'Proteção Passiva contra Incêndio',
        US: 'Passive Fire Protection',
        ES: 'Protección Pasiva contra Incendios'
    },
    
    heightWork: {
        PT: 'Trabalho em Altura',
        US: 'Work at Height',
        ES: 'Trabajo en Altura'
    },

    confinedSpace: {
        PT: 'Espaço Confinado',
        US: 'Confined Space',
        ES: 'Espacio Confinado'
    },
    //--------- End Basics

    //--------- CoatingEvaluation
    pollutantIncidency: {
        PT: "Incidência de Contaminantes",
        US: "Incidence of Contaminants",
        ES: "Incidencia de Contaminantes"
    },

    mechanicDamage: {
        PT: "Incidência de Danos Mecânicos",
        US: "Incidence of Mechanical Damage",
        ES: "Incidencia de Daños Mecánicos"
    },

    coatingDegradation: {
        PT: "Dano no Revestimento",
        US: "Coating Damage",
        ES: "Daño en el Revestimiento"
    },

    coatingDetachment: {
        PT: "Descolamento do Revestimento",
        US: "Coating Detachment",
        ES: "Desprendimiento del Revestimiento"
    },

    degradationClass: {
        PT: "Classe da Degradação",
        US: "Degradation Class",
        ES: "Clase de Degradación"
    },

    degradationSeverity: {
        PT: "Severidade do Dano",
        US: "Damage Severity",
        ES: "Severidad del Daño"
    },
    //--------- End CoatingEvaluation

    //--------- SubstrateEvaluation
    corrosionCharacteristics: {
        PT: "Características da Corrosão",
        US: "Corrosion Characteristics",
        ES: "Características de la Corrosión"
    },

    aggressive: {
        PT: "Quantidade Pites/Alvéolos agressivos",
        US: "Quantity of Aggressive Pits/Honeycombs",
        ES: "Cantidad de Pits/Alvéolos Agressivos"
    },

    underCorrosion: {
        PT: "Corrosão Sob Filme",
        US: "Underfilm Corrosion",
        ES: "Corrosión Bajo Película"
    },

    galvanicCorrosion: {
        PT: "Incidência de Corrosão Galvânica",
        US: "Incidence of Galvanic Corrosion",
        ES: "Incidencia de Corrosión Galvánica"
    },

    corrosionSeverity: {
        PT: "Severidade da Corrosão",
        US: "Corrosion Severity",
        ES: "Severidad de la Corrosión"
    },

    corrosion: {
        PT: "% Corrosão",
        US: "% Corrosion",
        ES: "% Corrosión"
    },

    materialLoss: {
        PT: "Perda de Material",
        US: "Material Loss",
        ES: "Pérdida de Material"
    },
    //--------- End SubstrateEvaluation

    //--------- SectorFootage
    itemDescription: {
        PT: "Descrição do Item",
        US: "Item Description",
        ES: "Descripción del Ítem"
    },

    dimensionPol: {
        PT: "Dimensão em Polegadas",
        US: "Dimension in Inches",
        ES: "Dimensión en Pulgadas"
    },

    dimensionMeter: {
        PT: "Dimensão em Metros",
        US: "Dimension in Meters",
        ES: "Dimensión en Metros"
    },

    inputWidth: {
        PT: "Largura de Entrada",
        US: "Input Width",
        ES: "Ancho de Entrada"
    },

    inputLength: {
        PT: "Comprimento de Entrada",
        US: "Input Length",
        ES: "Longitud de Entrada"
    },

    quantity: {
        PT: "Quantidade",
        US: "Quantity",
        ES: "Cantidad"
    },

    totalArea: {
        PT: "Área Total",
        US: "Total Area",
        ES: "Área Total"
    },
    //--------- End SectorFootage

    recommendations: {
        PT: 'Recomendação',
        US: 'Recommendations',
        ES: 'Recomendaciones'
    },

    close: {
        PT: 'Fechar',
        US: 'Close',
        ES: 'Cerrar'
    },

    exportXLSX: {
        PT: 'Exportar como XLSX',
        US: 'Export as XLSX',
        ES: 'Exportar como XLSX'
    },

    generalInformation: {
        PT: 'Informações Gerais',
        US: 'General Information',
        ES: 'Información General'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    approve: {
        PT: 'Aprovar',
        US: 'Approve',
        ES: 'Aprobar'
    },

    notification: {
        title: {
            PT: 'Inspeção verificada',
            US: 'Inspection verified',
            ES: 'Inspección verificada'
        },
        content: {
            PT: 'A inspeção {{inspectionTitle}} acabou de ser verificada!',
            US: 'The inspection {{inspectionTitle}} was just verified!',
            ES: '¡La inspección {{inspectionTitle}} acaba de ser verificada!'
        }
    },

    rectifications: {
        PT: 'Retificações',
        US: 'Rectifications',
        ES: 'Retificaciones'
    },

    rectify: {
        PT: 'Retificar',
        US: 'Rectify',
        ES: 'Rectificar'
    },

    comments: {
        PT: 'Comentários',
        US: 'Comments',
        ES: 'Comentarios'
    },

    inspectionRectification: {
        PT: 'Retificação de inspeção',
        US: 'Inspection Rectification',
        ES: 'Rectificación de Inspección'
    },

    visualInspection: {
        PT: 'Inspeção Visual',
        US: 'Visual Inspection',
        ES: 'Inspección Visual'
    },
}

export default lang;
