const lang: any = {
    plantAge: {
        PT: 'Idade da Planta',
        US: 'Plant Age',
        ES: 'Edad de la Planta'
    },

    processAge: {
        PT: 'Idade do Processo',
        US: 'Process Age',
        ES: 'Edad del Proceso'
    },

    equipmentAge: {
        PT: 'Idade do Equipamento',
        US: 'Equipment Age',
        ES: 'Edad del Equipo'
    },

    years: {
        PT: 'anos',
        US: 'years',
        ES: 'años'
    },

    yes: {
        PT: 'Sim',
        US: 'Yes',
        ES: 'Si'
    },

    no: {
        PT: 'Não',
        US: 'No',
        ES: 'No'
    },

    justify: {
        PT: 'Justificativa',
        US: 'Justification',
        ES: 'Justificación'
    },

    eqpAdd: {
        PT: 'Houve algum novo equipamento ou estrutura instalados após a data de ereção?',
        US: 'Has any new equipment or structure been installed after the erection date?',
        ES: '¿Se ha instalado algún equipo o estructura nueva después de la fecha de erección?'
    },

    specify: {
        PT: 'Especifique',
        US: 'Specify',
        ES: 'Especifique'
    },

    acc: {
        PT: 'Conhece a ACC (Condição Corrosiva Atmosférica) da instalação?',
        US: 'Do you know the ACC (Atmospheric Corrosive Condition) of the installation?',
        ES: '¿Conoce la ACC (Condición Corrosiva Atmosférica) de la instalación?'
    },

    coatingType: {
        PT: 'Conhece o tipo de revestimento ou sistemas de pintura usados nas estruturas e equipamentos da instalação?',
        US: 'Do you know the type of coating or painting systems used on the structures and equipment of the installation?',
        ES: '¿Conoce el tipo de revestimiento o sistemas de pintura utilizados en las estructuras y equipos de la instalación?'
    },

    installationConstruction: {
        PT: 'Que material é o mais usado para construção da instalação?',
        US: 'What material is most used for the construction of the installation?',
        ES: '¿Qué material se utiliza más para la construcción de la instalación?'
    },

    lastPainting: {
        PT: 'Sabe quando foi realizada a última manutenção de pintura / revestimentos de sua instalação?',
        US: 'When the last maintenance of paint / coatings of your installation was carried out?',
        ES: '¿Sabe cuándo se realizó el último mantenimiento de pintura / revestimientos de su instalación?'
    },

    tfm: {
        PT: 'O sistema de revestimento falhou antes da TFM (Tempo para primeira manutenção)?',
        US: 'Did the coating system failed before the TFM (Time to First Maintenance)?',
        ES: '¿El sistema de revestimiento falló antes del TFM (Tiempo para el primer mantenimiento)?'
    },

    historicalData: {
        PT: 'Há algum dado histórico de sistemas de revestimentos anteriores usados para sua instalação?',
        US: 'Is there any historical data on previous coating systems used for your installation?',
        ES: '¿Hay algún dato histórico de sistemas de revestimientos anteriores utilizados para su instalación?'
    },

    safetyProblems: {
        PT: 'Houve alguma queda de equipamento ou parte de estrutura devido a problemas de corrosão? Acarretou algum problema de segurança?',
        US: 'Has there been any equipment or structural failure due to corrosion issues? Did it result in any safety problems? How long ago this was?',
        ES: '¿Ha habido alguna caída de equipo o parte de estructura debido a problemas de corrosión? ¿Ocasionó algún problema de seguridad? ¿Cuánto tiempo ha sido?'
    },

    repairFactors: {
        "PT": "Pode-se identificar algum fator que afete o reparo/manutenção de suas instalações?",
        "US": "Can any factors be identified that affect the repair/maintenance of your facilities?",
        "ES": "¿Se puede identificar algún factor que afecte la reparación/mantenimiento de sus instalaciones?"
    },

    thermalInsulation: {
        "PT": "Há alguma tubulação ou equipamento com isolamento térmico na sua instalação?",
        "US": "Is there any piping or equipment with thermal insulation at your facility?",
        "ES": "¿Hay alguna tubería o equipo con aislamiento térmico en su instalación?"
    },

    chemicalsAffectingCoating: {
        "PT": "Existem produtos químicos estocados em sua instalação que possam afetar o sistema de revestimento e ou criar corrosão adicional?",
        "US": "Are there any chemicals stored at your facility that might affect the coating system or create additional corrosion?",
        "ES": "¿Existen productos químicos almacenados en su instalación que puedan afectar el sistema de revestimiento o crear corrosión adicional?"
    },

    galvanizedSteel: {
        "PT": "Aço galvanizado",
        "US": "Galvanized steel",
        "ES": "Acero galvanizado"
    },

    stainlessSteel: {
        "PT": "Inox",
        "US": "Stainless steel",
        "ES": "Acero inoxidable"
    },

    carbonSteel: {
        "PT": "Aço Carbono",
        "US": "Carbon steel",
        "ES": "Acero al carbono"
    },

    otherMaterial: {
        "PT": "Outro",
        "US": "Other",
        "ES": "Otro"
    },

    //EQUIPAMENTO
    
    newEquipmentAdded: {
        "PT": "Houve algum novo equipamento, estrutura ou edifícios instalados ou adicionados após a data de início de operação do equipamento?",
        "US": "Has any new equipment, structure, or buildings been installed or added after the equipment's start of operation date?",
        "ES": "¿Ha habido algún nuevo equipo, estructura o edificios instalados o agregados después de la fecha de inicio de operación del equipo?"
    },
    
    listNewEquipment: {
        "PT": "Pode fornecer uma lista dos novos equipamentos, estrutura ou edifícios instalados onde o equipamento afetado se encontra e quando isso foi feito?",
        "US": "Can you provide a list of the new equipment, structure, or buildings installed where the affected equipment is located and when it was done?",
        "ES": "¿Puede proporcionar una lista de los nuevos equipos, estructuras o edificios instalados donde se encuentra el equipo afectado y cuándo se hizo?"
    },
    
    ACCCondition: {
        "PT": "Conhece a ACC (Condição Corrosiva Atmosférica) de onde a sua Planta e equipamentos estão instalados?",
        "US": "Do you know the ACC (Atmospheric Corrosive Condition) where your Plant and equipment are installed?",
        "ES": "¿Conoce la ACC (Condición Corrosiva Atmosférica) donde están instalados su Planta y equipos?"
    },
    
    coatingSystem: {
        "PT": "Conhece o sistema de revestimento ou sistema de pintura especificados para a proteção de seu equipamento?",
        "US": "Do you know the coating or painting system specified for the protection of your equipment?",
        "ES": "¿Conoce el sistema de revestimiento o sistema de pintura especificados para la protección de su equipo?"
    },
    
    materialUsed: {
        "PT": "Que tipo de material é usado para construção do equipamento?",
        "US": "What type of material is used for the construction of the equipment?",
        "ES": "¿Qué tipo de material se utiliza para la construcción del equipo?"
    },
    
    lastMaintenance: {
        "PT": "Sabe se houve e quando foi realizada a última manutenção de pintura do equipamento?",
        "US": "Do you know if there was and when the last paint maintenance of the equipment was carried out?",
        "ES": "¿Sabe si hubo y cuándo se realizó el último mantenimiento de pintura del equipo?"
    },
    
    TFMBeforeFailure: {
        "PT": "Sabe se o sistema de revestimento falhou antes da TFM (Tempo para primeira manutenção)?",
        "US": "Do you know if the coating system failed before the TFM (Time to First Maintenance)?",
        "ES": "¿Sabe si el sistema de revestimiento falló antes del TFM (Tiempo para el primer mantenimiento)?"
    },
    
    reasonForFailure: {
        "PT": "Se o sistema de revestimento falhou antes da TFM, sabe por que falhou?",
        "US": "If the coating system failed before the TFM, do you know why it failed?",
        "ES": "Si el sistema de revestimiento falló antes del TFM, ¿sabe por qué falló?"
    },

    historicalCoatingData: {
        "PT": "Há algum dado histórico de sistemas de revestimentos ou de pintura anteriores usados no equipamento em questão?",
        "US": "Is there any historical data on previous coating or painting systems used on the equipment in question?",
        "ES": "¿Hay algún dato histórico de sistemas de revestimientos o de pintura anteriores utilizados en el equipo en cuestión?"
    },
    
    equipmentFailureDueToCorrosion: {
        "PT": "Houve alguma queda de equipamento ou parte de estrutura devido a problemas de corrosão?",
        "US": "Has there been any equipment or structural failure due to corrosion problems?",
        "ES": "¿Ha habido alguna caída de equipo o parte de estructura debido a problemas de corrosión?"
    },
    
    safetyIncidents: {
        "PT": "Acarretou algum problema de segurança?",
        "US": "Did it result in any safety problems?",
        "ES": "¿Ocasionó algún problema de seguridad?"
    },
    
    timeSinceFailure: {
        "PT": "Se houve queda de equipamento ou parte de estrutura devido a problemas de corrosão, sabe há quanto tempo isso foi?",
        "US": "If there has been equipment or structural failure due to corrosion, do you know how long ago this was?",
        "ES": "Si ha habido caída de equipo o parte de estructura debido a problemas de corrosión, ¿sabe cuánto tiempo ha sido?"
    },
    
    repairMaintenanceFactors: {
        "PT": "Pode identificar algum fator que afete o reparo/manutenção de seus equipamentos?",
        "US": "Can you identify any factors that affect the repair/maintenance of your equipment?",
        "ES": "¿Puede identificar algún factor que afecte la reparación/mantenimiento de sus equipos?"
    },
    
    thermalInsulationOnEquipment: {
        "PT": "Há alguma tubulação ou equipamento com isolamento térmico na sua unidade operacional?",
        "US": "Is there any piping or equipment with thermal insulation in your operational unit?",
        "ES": "¿Hay alguna tubería o equipo con aislamiento térmico en su unidad operativa?"
    },
    
    insulationSurfaceInspection: {
        "PT": "Foi realizada alguma Inspeção nas superfícies isoladas de tubulação e/ou equipamento?",
        "US": "Has there been any inspection on the insulated surfaces of piping and/or equipment?",
        "ES": "¿Se ha realizado alguna inspección en las superficies aisladas de tuberías y/o equipos?"
    },
    
    lastInspectionDate: {
        "PT": "Quando foi a última vez que isso foi realizado? Informe a data.",
        "US": "When was the last time this was done? Please provide the date.",
        "ES": "¿Cuándo fue la última vez que se realizó esto? Por favor, proporcione la fecha."
    },
    
    identifyThermalSystems: {
        "PT": "Seria capaz de identificar, separadamente, cada sistema de tubulação ou equipamento isolado termicamente?",
        "US": "Would you be able to identify, separately, each thermally insulated piping or equipment system?",
        "ES": "¿Sería capaz de identificar, por separado, cada sistema de tuberías o equipo aislado térmicamente?"
    },
    
    damagedInsulationMaterial: {
        "PT": "Há algum material de isolamento danificado em sua tubulação ou equipamento?",
        "US": "Do you have any damaged insulation material on your piping or equipment?",
        "ES": "¿Tiene algún material de aislamiento dañado en su tubería o equipo?"
    },
    
    equipmentInUse: {
        "PT": "A tubulação ou o equipamento ainda estão em uso?",
        "US": "Is the piping or equipment still in use?",
        "ES": "¿La tubería o el equipo todavía están en uso?"
    },
    
    thermalTracing: {
        "PT": "É usado rastreamento térmico para tubulação isolada e ou equipamento?",
        "US": "Do you use thermal tracing for insulated piping and or equipment?",
        "ES": "¿Utiliza trazado térmico para tuberías aisladas y/o equipos?"
    },

    mixedMaterials: {
        "PT": "Há ou é usado qualquer material diferente soldado ou aparafusado, exemplo de aço carbono com aço inoxidável etc.?",
        "US": "Do you have or use any different materials welded or bolted together, such as carbon steel with stainless steel, etc.?",
        "ES": "¿Tiene o usa algún material diferente soldado o atornillado, como acero al carbono con acero inoxidable, etc.?"
    },
    
    chemicalsAffectingEquipmentCoating: {
        "PT": "Existem produtos químicos estocados em sua instalação que possam afetar o sistema de revestimento do equipamento e ou criar corrosão adicional?",
        "US": "Are there any chemicals stored at your facility that might affect the coating system of the equipment or create additional corrosion?",
        "ES": "¿Existen productos químicos almacenados en su instalación que puedan afectar el sistema de revestimiento del equipo o crear corrosión adicional?"
    },
    
    acidFumes: {
        "PT": "Existem emanações de ácidos ou fumos em sua instalação que possam afetar o sistema de revestimento e ou acelerar a corrosão?",
        "US": "Are there any acid fumes or emissions at your facility that might affect the coating system or accelerate corrosion?",
        "ES": "¿Existen emanaciones de ácidos o humos en su instalación que puedan afectar el sistema de revestimiento o acelerar la corrosión?"
    }
}

export default lang;
