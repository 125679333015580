import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";


// FORMAT OF DATE: 2021-01-18 22:40:50

export function formatTimestamp(datetime: string|undefined, fuso=true, raw=false){
    if (!datetime)
      return 'None';
  
    let date = new Date(datetime);
  
    if(fuso){
      const brazilOffset = -3 * 60 * 60000;           // Offset de Brasília (UTC-3) em milissegundos
      date = new Date(date.getTime() + brazilOffset) // Convertendo para o horário de Brasília
    }
    
    return raw ? date : date.toLocaleString('pt-BR')
}

export function getDeadline(date: any){
    if(typeof(date) == 'string')
      date = new Date(date)
  
    const year    = date.getFullYear();
    const month   = (date.getMonth() + 1).toString().padStart(2, '0');
    const day     = date.getDate().toString().padStart(2, '0');
    const hours   = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
  
export function sortArrayDatetime(array: any, key: string){
    return array.sort((a: any, b: any) => {
        let dateA: any = new Date(a[key]);
        let dateB: any = new Date(b[key]);
        return dateB - dateA;
    })
}

export function DateSelector({ value, onChange, placeholder }: any){
    function handleChange(date: Date|null){
        if(!date)
            date = getDate('now')

        const formatted: string = getDeadline(date)
        onChange(formatted)
    }

    function getDate(value: string){
        const date = (value == 'now') ? new Date() : new Date(value)
        
        if(value == 'now')
            handleChange(date)

        return date
    }

    return (
        <div className='datepicker'>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText=" " // Espaço em branco para o placeholder nativo
                selected={getDate(value)}
                onChange={(value) => handleChange(value)}
                className='datepicker-input'
                showYearDropdown
            />

            <label>{placeholder}</label> {/* Etiqueta para o efeito de shrink */}
        </div>
    );
};