import { useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useInitialCheck, useInterval } from '../../../utils';

export default function CustomPieChart({data=[], propConst=0.03, hasData}: any) {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    
    useInterval(updateDimensions, 200)
    const propDim = Math.floor(Math.sqrt(width*width + height*height)) * propConst

    function updateDimensions(){
        if(window.innerWidth != width)
            setWidth(window.innerWidth)

        if(window.innerHeight != height)
            setHeight(window.innerHeight)
    }

    const defaultData = [
        { name: 'Não há dados', value: 1, color: 'grey' },
    ]

    if(hasData == false){
        data = defaultData
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={propDim}
                    stroke="none"
                    label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
                        const RADIAN = Math.PI / 180;
                        const radius = innerRadius + (outerRadius - innerRadius) * 0.4
                        const x = cx + radius * Math.cos(-midAngle * RADIAN)
                        const y = cy + radius * Math.sin(-midAngle * RADIAN)

                        return (
                          <text x={x} y={y} fontSize={hasData ? '14px' : '0px'} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                            {`${(percent * 100).toFixed(0)}%`}
                          </text>
                        );
                      }}
                    labelLine={false}
                >
                    {
                        data.map((entry: any, index: any) => <Cell key={`cell-${index}`} fill={entry.color}/>)
                    }
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
}
