const lang: any = {
    filters: {
        PT: 'Filtros',
        US: 'Filters'
    },

    company: {
        PT: 'Empresa',
        US: 'Company'
    },

    inspector: {
        PT: 'Inspetor',
        US: 'Inspector'
    },

    inspection: {
        PT: 'Inspeção',
        US: 'Inspection'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant'
    },

    process: {
        PT: 'Processo',
        US: 'Process'
    },

    equipment: {
        PT: 'Equipamento',
        US: 'Equipment'
    },

    showResults: {
        PT: 'Mostrar Resultados',
        US: 'Show Results'
    }
}

export default lang