const lang: any = {
    selectCompany: {
        'PT': 'Selecione a Empresa',
        'US': 'Select Company',
        'ES': 'Seleccione la Empresa'
    },

    corrosionRates: {
        'PT': 'Índices de Corrosão',
        'US': 'Corrosion Rates',
        'ES': 'Índices de Corrosión'
    },

    selectPlant: {
        'PT': 'Selecione a planta',
        'US': 'Select Plant',
        'ES': 'Seleccione la Planta'
    },

    consult: {
        'PT': 'Consultar',
        'US': 'Consult',
        'ES': 'Consultar'
    },

    ofcorrosion: {
        'PT': 'de Corrosão',
        'US': 'of Corrosion',
        'ES': 'de Corrosión'
    },

    equipment: {
        'PT': 'Equipamento',
        'US': 'Equipment',
        'ES': 'Equipo'
    },

    process: {
        'PT': 'Processo',
        'US': 'Process',
        'ES': 'Proceso'
    },

    numinsp: {
        'PT': 'Nº Inspeções',
        'US': 'Nº Inspections',
        'ES': 'Nº Inspecciones'
    },

    totalCorrosion: {
        'PT': 'Corrosão Total',
        'US': 'Total Corrosion',
        'ES': 'Corrosión Total'
    },

    degradation: {
        'PT': 'Degradação',
        'US': 'Degradation',
        'ES': 'Degradación'
    },

    severity: {
        'PT': 'Severidade',
        'US': 'Severity',
        'ES': 'Severidad'
    },

    materialLoss: {
        'PT': 'Perda de Material',
        'US': 'Material Loss',
        'ES': 'Pérdida de Material'
    },

    observations: {
        'PT': 'Observações',
        'US': 'Observations',
        'ES': 'Observaciones'
    },
    
    graphs: {
        'PT': 'Gráficos',
        'US': 'Graphs',
        'ES': 'Gráficos'
    },

    reinspectAfter: {
        'PT': 'REINSPENCIONAR EM ATÉ',
        'US': 'REINSPECT AFTER',
        'ES': 'REINSPECCIONAR DESPUÉS'
    },

    repairUntil: {
        'PT': 'REPAROS LOCALIZADOS EM ATÉ',
        'US': 'REPAIRS LOCATED IN',
        'ES': 'REPARACIONES LOCALIZADAS EN'
    },

    substitution: {
        'PT': 'SUBSTITUIÇÃO OU REPARAÇÃO EM ATÉ',
        'US': 'SUBSTITUTION OR REPAIR WITHIN',
        'ES': 'SUSTITUCIÓN O REPARACIÓN DENTRO DE'
    },

    year: {
        'PT': 'ANO',
        'US': 'YEAR',
        'ES': 'AÑO'
    },

    coatingDegradation: {
        PT: "Dano no Revestimento",
        US: "Coating Damage",
        ES: "Daño en el Revestimiento"
    },

    degradationClass: {
        PT: "Classe da Degradação",
        US: "Degradation Class",
        ES: "Clase de Degradación"
    },

    export: {
        PT: 'Exportar',
        US: 'Export',
        ES: 'Exportar'
    },
}

export default lang
