const lang: any = {
    inspStatus: {
        PT: 'Status de inspeção dos equipamentos',
        US: 'Inspection equipment status',
        ES: 'Estado de inspección del equipo'
    },

    noEquipment: {
        PT: 'Nenhum equipamento',
        US: 'Equipment not found',
        ES: 'Equipo no encontrado'
    },

    inspections: {
        PT: 'Inspeções',
        US: 'Inspections',
        ES: 'Inspecciónes'
    },

    technicalData: {
        PT: 'Dados Técnicos',
        US: 'Technical Data',
        ES: 'Datos Técnicos'
    },

    of: {
        PT: 'de',
        US: 'of',
        ES: 'de'
    },

    inspected: {
        PT: 'Inspecionados',
        US: 'Inspected',
        ES: 'Inspeccionados'
    },

    notInspected: {
        PT: 'Não inspecionados',
        US: 'Not inspected',
        ES: 'No inspeccionados'
    },

    general: {
        PT: 'Geral',
        US: 'General',
        ES: 'General'
    },

    assets: {
        PT: 'Ativos',
        US: 'Assets',
        ES: 'Activos'
    },

    lastInspectionIn: {
        PT: 'Última inspeção em',
        US: 'Last inspection',
        ES: 'Última inspección en'
    },

    widthLoss: {
        PT: 'Perda de espessura',
        US: 'Width loss',
        ES: 'Pérdida de espesor'
    },

    isolation: {
        PT: 'Isolamento',
        US: 'Isolation',
        ES: 'Aislamiento'
    },

    visualInspection: {
        PT: 'Inspeção visual',
        US: 'Visual inspection',
        ES: 'Inspección visual'
    },

    lifespan: {
        PT: 'Vida útil restante',
        US: 'Lifespan',
        ES: 'Vida útil restante'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    lastInspection: {
        PT: 'Última Inspeção',
        US: 'Last Inspection',
        ES: 'Última Inspección'
    },

    form: {
        PT: 'Formulário',
        US: 'Form',
        ES: 'Formulario'
    },

    documents: {
        PT: 'Documentos',
        US: 'Documents',
        ES: 'Documentos'
    },

    readForm: {
        PT: 'Formulário de cadastro de ativo',
        US: 'Asset Registration Form',
        ES: 'Formulario de Registro de Activo'
    },

    formMessage: {
        PT: 'Insira um email e uma mensagem para serem enviados ou copie o link e envie diretamente',
        US: 'Enter an email and a message to be sent, or copy the link and send it directly',
        ES: 'Introduzca un correo electrónico y un mensaje para enviar, o copie el enlace y envíelo directamente'
    },

    emailMsg: {
        PT: "Prezado(a),\n\nPor favor, preencha o formulário disponível no link abaixo para fornecer informações necessárias sobre o ativo. Sua colaboração é importante para nós.\n\nAgradecemos sua atenção e colaboração.\n\nAtenciosamente,\nIntegrAtivos",
        US: "Dear [Sir/Madam],\n\nPlease fill out the form available at the link below to provide necessary information about the asset. Your cooperation is important to us.\n\nThank you for your attention and cooperation.\n\nSincerely,\nIntegrAtivos",
        ES: "Estimado(a),\n\nPor favor, complete el formulario disponible en el enlace a continuación para proporcionar la información necesaria sobre el activo. Su colaboración es importante para nosotros.\n\nAgradecemos su atención y colaboración.\n\nAtentamente,\nIntegrAtivos"
    },

    formFillingRequest: {
        PT: "Solicitação de Preenchimento de Formulário",
        US: "Form Completion Request",
        ES: "Solicitud de Cumplimiento de Formulario"
    },

    formRegister: {
        PT: 'Formulário de cadastro',
        US: 'Registration form',
        ES: 'Formulario de registro'
    },
}

export default lang;
