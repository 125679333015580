const lang: any = {
    whichCompany: {
        PT: 'Qual empresa?',
        US: 'Which company?',
        ES: '¿Qué empresa?'
    },

    clean: {
        PT: 'limpar',
        US: 'clean',
        ES: 'limpiar'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    search: {
        PT: 'Pesquisar',
        US: 'Search',
        ES: 'Buscar'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    searchCompanies: {
        PT: 'Pesquisar Empresas',
        US: 'Search Companies',
        ES: 'Buscar Empresas'
    },

    searchPlants: {
        PT: 'Pesquisar Plantas',
        US: 'Search Plants',
        ES: 'Buscar Plantas'
    },

    searchProcesses: {
        PT: 'Pesquisar Processos',
        US: 'Search Processes',
        ES: 'Buscar Processos'
    },

    searchEquipments: {
        PT: 'Pesquisar Equipamentos',
        US: 'Search Equipments',
        ES: 'Buscar Equipamentos'
    }
}

export default lang;
