import './styles.css'

export const Table = ({ children }: any) => {
    return (<div className="table">{children}</div>)
}

export const TableCell = ({ children, color='white' }: any) => {
    return (<div className="table-cell" style={{color: color}}>{children}</div>)
}

export const TableRow = ({ children, onClick=undefined }: any) => {
    return (<div className="table-row" onClick={onClick}>{children}</div>)
}

