import React, { useContext, useEffect } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { url } from './database';

// Um Hook personalizado que executa um callback em um intervalo especificado
export function useInterval(callback: any, delay: number) {
	useEffect(() => {
		const intervalId = setInterval(callback, delay);

		return function cleanup() {
			clearInterval(intervalId);
		};
	}, [callback, delay]);
}

// Um Hook personalizado que executa uma função de verificação quando o componente é montado
export function useInitialCheck(checkFunction: any, params: any = []) {
	useEffect(() => {
		async function check() {
			await checkFunction();
		}

		check();
	}, params);
}

export function CheckLogged() {
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const navigate = useNavigate()

	useEffect(() => {
		if (!globalOptions.logged)
			return navigate('/')
	}, [globalOptions]);
}

// Você pode manter esta função como está, não precisa ser um Hook
export async function sleep(ms: number) {
	await new Promise(resolve => setTimeout(resolve, ms));
}

export function saveJsonData(key: string, data: any) {
	try {
		const jsonData = JSON.stringify(data);
		localStorage.setItem(key, jsonData);
		return true
	}
	catch (error) {
		console.error("Error saving data to localStorage", error);
		return false
	}
}

export function getStoredJson(key: string, onEmpty: any = null) {
	try {
		const jsonData = localStorage.getItem(key);
		return jsonData ? JSON.parse(jsonData) : onEmpty;
	}
	catch (error) {
		console.error("Error retrieving data from localStorage", error);
		return onEmpty;
	}
}

export function deleteJsonData(key: string) {
	try {
		localStorage.removeItem(key);
		console.log(`Data with key '${key}' has been successfully removed from localStorage`);
		return true;
	} catch (error) {
		console.error("Error removing data from localStorage", error);
		return false;
	}
}

export function getCategory(category: string, reverse: boolean = false) {
	const categories = [
		{ value: "manager", name: 'Gerente' },
		{ value: "auxiliary", name: 'Auxiliar' },
		{ value: "admin", name: 'Administrador' }
	]

	if (!reverse)
		for (let item of categories)
			if (category == item.name)
				return item.value

	for (let item of categories)
		if (category == item.value)
			return item.name

	return ''
}

type Notification = {
	itemId: number|string;
	userId: number;
	title: string;
	msg: string;
	type: string;
	data: {};
}

export async function sendNotification(data: Notification) {
	try {
		const response = await fetch(`${url}notify`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})

		if (!response.ok)
			throw new Error('Erro ao requisitar envio de notificação')
	}
	catch (error) {
		const e = error as Error
		console.error(e.message)
		alert(e.message)
	}
}

// CONVERTE UM NÚMERO ALEATÓRIO PARA UMA STRING E FORMATA COM ZEROS A ESQUERDA
export function randomNumericString(size: number){
	var number = Math.floor(Math.random() * 10000)
	var string = number.toString().padStart(size, '0')
  
	return string
}

// PROCESSAMENTO PARA VALIDAR A CONTRA-SENHA GERADA PARA O DISPOSITIVO
export function parsePassword(txt: any){
	let num = Number(txt)
	
	if (isNaN(num) || num == 0)
	  return 0

	num = num*2 + 3
	return Math.floor(num > 9999 ? num / 10 : num)
}