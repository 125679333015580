export const enum NotificationTypes {
    NOTIFY_NEAR_END_INSPECTIONS = 'nearEnd',
    NOTIFY_NEW_INSPECTION = 'newInspection',
    NOTIFY_INSPECTION_APPROVED = 'approved',
}

export function getNormalizedInspectorLang(lang: string) {
    let translation = 'US'
    if (lang === 'pt_BR')
        translation = 'PT'
    else if (lang === 'es_ES')
        translation = 'ES'

    return translation
}