const lang: any = {
    title: {
        PT:'Requisitar exclusão de conta',
        US:'Request account deletion',
        ES:'Solicitar eliminación de cuenta',
    },
    text: {
        PT:`Para a exclusão de sua conta e todos os seus dados relacionados, preencha o campo abaixo com seu e-mail.
        Ao clicar em enviar, enviaremos um e-mail para confirmar se a conta deve ser excluída. Caso seja confirmado,
        sua conta será excluída de imediato.
        <br/>
        Caso não tenha recebido o e-mail de confirmação, por favor, entre em contato com: grva@lamce.coppe.ufrj.br
        `,
        US:`To delete your account and all related data, please fill in the field below with your email.
        By clicking submit, we will send you an email to confirm if the account should be deleted. If confirmed, your account will be deleted immediately.
        <br/>
        If you have not received the confirmation email, please contact: grva@lamce.coppe.ufrj.br
        `,
        ES:`Para eliminar su cuenta y todos sus datos relacionados, complete el campo a continuación con su correo electrónico.
        Al hacer clic en enviar, le enviaremos un correo electrónico para confirmar si se debe eliminar la cuenta. Si se confirma, su cuenta se eliminará de inmediato.
        <br/>
        Si no ha recibido el correo electrónico de confirmación, comuníquese con: grva@lamce.coppe.ufrj.br       
        `
    },
    typeEmail: {
        PT: 'Digite seu e-mail',
        US: 'Enter your e-mail',
        ES: 'Introduzca su e-mail'
    },
    send: {
        PT: 'Enviar',
        US: 'Send',
        ES: 'Enviar'
    },
    modalTitle: {
        PT: "Enviado",
        US: "Sent",
        ES: "Enviado"
    },
    modalText: {
        PT: "Solicitação enviada com sucesso! Em breve enviaremos um e-mail pedindo confirmação.",
        US: "Request successfully sent! We will soon send you an email requesting confirmation.",
        ES: "¡Solicitud enviada con éxito! Pronto le enviaremos un correo electrónico solicitando confirmación."
    },
    buttonText: {
        PT: "Confirmar",
        US: "Confirm",
        ES: "Confirmar"
    },
    subject: {
        PT: "Exclusão de conta",
        US: "Account deletion",
        ES: "Eliminación de cuenta"
    }
}

export default lang