const lang: any = {
    registerProcess: {
        PT: 'Cadastrar Novo Processo',
        US: 'Register New Process',
        ES: 'Registrar Nuevo Proceso'
    },

    editProcess: {
        PT: 'Editar Processo',
        US: 'Edit Process',
        ES: 'Editar Proceso'
    },

    processInfo: {
        PT: 'INFORMAÇÕES DO PROCESSO',
        US: 'PROCESS INFO',
        ES: 'INFORMACIÓN DEL PROCESO'
    },

    name: {
        PT: 'Nome',
        US: 'Name',
        ES: 'Nombre'
    },
    processCapture: {
        PT: 'IMAGEM CAPTURADA',
        US: 'IMAGE CAPTURED',
        ES: 'IMAGEN CAPTURADA'
    },
    next: {
        PT: 'Próximo',
        US: 'Next',
        ES: 'Siguiente'
    },
    processMarker: {
        PT: 'INDIQUE O LOCAL DO PROCESSO NO MAPA',
        US: 'SHOW THE PROCESS LOCATION ON THE MAP',
        ES: 'INDICAR LA UBICACIÓN DEL PROCESO EN EL MAPA'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    documents: {
        PT: 'Documentos',
        US: 'Documents',
        ES: 'Documentos'
    },

    photos: {
        PT: 'Fotos',
        US: 'Photos',
        ES: 'Fotos'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    formRegister: {
        PT: 'Formulário de cadastro',
        US: 'Registration form',
        ES: 'Formulario de registro'
    },

    formMessage: {
        PT: 'Insira um email e uma mensagem para serem enviados ou copie o link e envie diretamente',
        US: 'Enter an email and a message to be sent, or copy the link and send it directly',
        ES: 'Introduzca un correo electrónico y un mensaje para enviar, o copie el enlace y envíelo directamente'
    },

    emailMsg: {
        PT: "Prezado(a),\n\nPor favor, preencha o formulário disponível no link abaixo para fornecer informações necessárias sobre o ativo. Sua colaboração é importante para nós.\n\nAgradecemos sua atenção e colaboração.\n\nAtenciosamente,\nIntegrAtivos",
        US: "Dear [Sir/Madam],\n\nPlease fill out the form available at the link below to provide necessary information about the asset. Your cooperation is important to us.\n\nThank you for your attention and cooperation.\n\nSincerely,\nIntegrAtivos",
        ES: "Estimado(a),\n\nPor favor, complete el formulario disponible en el enlace a continuación para proporcionar la información necesaria sobre el activo. Su colaboración es importante para nosotros.\n\nAgradecemos su atención y colaboración.\n\nAtentamente,\nIntegrAtivos"
    },

    formFillingRequest: {
        PT: "Solicitação de Preenchimento de Formulário",
        US: "Form Completion Request",
        ES: "Solicitud de Cumplimiento de Formulario"
    },
}

export default lang;
