const lang: any = {
    companies: {
        PT: 'Empresas',
        US: 'Companies',
        ES: 'Empresas'
    },

    inspectors: {
        PT: 'Inspetores',
        US: 'Inspectors',
        ES: 'Inspectores'
    },

    accesses: {
        PT: 'Acessos',
        US: 'Accesses',
        ES: 'Accesos'
    },

    clients: {
        PT: 'Clientes',
        US: 'Clients',
        ES: 'Clientes'
    },

    totalInspections: {
        PT: 'Total de Inspeções',
        US: 'Total Inspections',
        ES: 'Total de Inspecciones'
    },

    totalRegisters: {
        PT: 'Total de Registros',
        US: 'Total of Registers',
        ES: 'Total de Registros'
    },

    pendingInspections: {
        PT: 'Inspeções Pendentes',
        US: 'Pending Inspections',
        ES: 'Inspecciones Pendientes'
    },

    underInspections: {
        PT: 'Inspeções no Prazo',
        US: 'On-time Inspections',
        ES: 'Inspecciones a Tiempo'
    },

    statusInspections: {
        PT: 'Status de Inspeções',
        US: 'Inspections Status',
        ES: 'Estado de Inspección'
    },

    semanalRegisters: {
        PT: 'Registros Semanais',
        US: 'Semanal Registers',
        ES: 'Registros Semanales'
    },

    calendar: {
        PT: 'Agenda',
        US: 'Calendar',
        ES: 'Calendario'
    },

    typeHere: {
        PT: 'Digite aqui',
        US: 'Type here',
        ES: 'Escriba aquí'
    },

    noData: {
        PT: 'não há dados',
        US: 'no data',
        ES: 'sin datos'
    },

    admin: {
        PT: 'Administrador',
        US: 'Administrator',
        ES: 'Administrador'
    },

    inspector: {
        PT: 'Inspetor',
        US: 'Inspector',
        ES: 'Inspector'
    },

    read: {
        PT: 'LEITURA',
        US: 'READ',
        ES: 'LEER'
    },

    edit: {
        PT: 'EDIÇÃO',
        US: 'EDIT',
        ES: 'EDICIÓN'
    },

    register: {
        PT: 'CADASTRAR',
        US: 'REGISTER',
        ES: 'REGISTRAR'
    },

    accessControl: {
        PT: 'CONTROLE DE ACESSOS',
        US: 'ACCESS CONTROL',
        ES: 'CONTROL DE ACCESOS'
    },

    inspectorManagement: {
        PT: 'GERENCIAMENTO DE INSPETORES',
        US: 'INSPECTOR MANAGEMENT',
        ES: 'GESTIÓN DE INSPECTORES'
    },

    clientManagement: {
        PT: 'GERENCIAMENTO DE CLIENTES',
        US: 'CLIENT MANAGEMENT',
        ES: 'GESTIÓN DE CLIENTES'
    },

    plantManagement: {
        PT: 'GERENCIAMENTO DE PLANTAS',
        US: 'PLANT MANAGEMENT',
        ES: 'GESTIÓN DE PLANTAS'
    },

    processManagement: {
        PT: 'GERENCIAMENTO DE PROCESSOS',
        US: 'PROCESS MANAGEMENT',
        ES: 'GESTIÓN DE PROCESOS'
    },

    equipmentManagement: {
        PT: 'GERENCIAMENTO DE EQUIPAMENTOS',
        US: 'EQUIPMENT MANAGEMENT',
        ES: 'GESTIÓN DE EQUIPOS'
    },

    companyManagement: {
        PT: 'GERENCIAMENTO DE EMPRESAS',
        US: 'COMPANY MANAGEMENT',
        ES: 'GESTIÓN DE EMPRESAS'
    },

    reportManagement: {
        PT: 'GERENCIAMENTO DE RELATÓRIOS',
        US: 'REPORT MANAGEMENT',
        ES: 'GESTIÓN DE INFORMES'
    },

    update: {
        PT: 'ATUALIZAR',
        US: 'UPDATE',
        ES: 'ACTUALIZAR'
    },

    modifyAccess: {
        PT: 'Modificar Acesso',
        US: 'Modify Access',
        ES: 'Modificar Acceso'
    },

    manager: {
        PT: 'Gerente',
        US: 'Manager',
        ES: 'Gerente'
    },

    inspectionReports: {
        PT: 'Relatórios de Inspeção',
        US: 'Inspection Reports',
        ES: 'Informes de Inspección'
    },

    toEdit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    newInspection: {
        PT: 'Solicitar Nova Inspeção',
        US: 'Request New Inspection',
        ES: 'Solicitar Nueva Inspección'
    },

    registers: {
        PT: 'Registros',
        US: 'Registers',
        ES: 'Registros'
    },

    lateInspection: {
        PT: 'Atrasada',
        US: 'Late',
        ES: 'Atrasada'
    },

    onTimeInspection: {
        PT: 'Em dia',
        US: 'On time',
        ES: 'Al día'
    },

    notScheduled: {
        PT: 'Não programada',
        US: 'Not scheduled',
        ES: 'Al día'
    },

    scheduled: {
        PT: 'Programada',
        US: 'Scheduled',
        ES: 'Al día'
    },

    inspected: {
        PT: 'Inspecionada',
        US: 'Inspected',
        ES: 'Al día'
    },
    

}

export default lang;
