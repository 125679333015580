import { useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useInterval } from '../../../utils';

export default function CustomLinePlot({data=[], xAxisKey="x", yAxisKey="y", stroke="orange"}: any) {

    return (
        <ResponsiveContainer width="95%" height="100%">
            <LineChart
                data={data}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisKey} />
                <YAxis dataKey={yAxisKey} />
                <Tooltip />
                <Line type="monotone" dataKey={yAxisKey} stroke={stroke} />
            </LineChart>
        </ResponsiveContainer>
    );
}