import React, { useState, useEffect } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useInterval } from '../../../utils';

const defaultData = [
  { key: 'reinspect_after', label: 'RE-INSPENCIONAR APÓS', one_year: 5000, two_years: 2000, three_years: 7000 },
  { key: 'localized_repairs', label: 'REPAROS LOCALZADOS EM ATÉ', one_year: 4000, two_years: 8000, three_years: 10000 },
  { key: 'substitution', label: 'SUBSTITUIÇÃO OU REPARAÇÃO EM ATÉ', one_year: 1000, two_years: 7500, three_years: 2000 },
];

const defaultBars = [
    {key: 'one_year',    label: '1 ANO', color: 'blue'},
    {key: 'two_years',   label: '2 ANOS', color: 'orange'},
    {key: 'three_years', label: '3 ANOS', color: 'red'},
]

export default function CustomBarChart({ propConst = 0.03, data=defaultData, bars=defaultBars }: any) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  useInterval(updateDimensions, 200);

  function updateDimensions() {
    if (window.innerWidth !== width) setWidth(window.innerWidth);
    if (window.innerHeight !== height) setHeight(window.innerHeight);
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          {bars.map((item: any) => 
            (<Bar dataKey={item.key} fill={item.color} name={item.label}/>)
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
