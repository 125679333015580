import './styles.css'


export default function ScrollView({children, className='', style={}}: any){

    return (
        <div className={'ScrollViewContainer ' + className} style={style}>
            {children}
        </div>
    )
}