import { useState } from "react";
import FlagSelector from "../../../components/FlagSelector";
import Sidebar from "../../../components/Sidebar";
import './styles.css'
import {  faCalendarCheck, faCalendarDay, faCheck, faPlus, faPrint, faShare } from "@fortawesome/free-solid-svg-icons";
import SimpleDropDown from "../../../components/SimpleDropDown";
import CustomButton from "../../../components/CustomButton";
import { getStoredJson, saveJsonData, useInitialCheck } from "../../../utils";
import { getDataBaseCondition } from "../../../utils/database";
import CustomCalendar from "../../../components/CustomCalendar";

import { useNavigate } from "react-router-dom";


export function CalendarPage(){
    const [inspections, setInspections] = useState<any>([])
    const user = getStoredJson('user')

    const navigate = useNavigate()
    useInitialCheck(importInspections)

    async function importInspections(){
        const response = await getDataBaseCondition('inspections', 'requested_by', user.id)
        setInspections(response.filter((item: any) => item.approved == 0))
    }

    function handleSelectedClick(selectedEvent: any){
        if(!selectedEvent)
            return

        const data = inspections[selectedEvent.id]
        saveJsonData('pendingInspection', data)
        navigate('/Explore')
    }

    return (
        <div className="MainContainer">
            <FlagSelector/>
            <Sidebar activePage='Home'/>
    
            <div className='SideBarCompensation'/>
            
            <div className='CentralBlock'>
                <div className="itemsContainer">
                    <div className="CalendarSecondRow">
                        <div className="CalendarOptionsContainer">    
                            <CustomButton
                                title={'Calendário'}
                                icon={faCheck}
                                containerStyle={{justifyContent: 'flex-start'}}
                            />

                            <CustomButton
                                title={'Exibir Tudo'}
                                icon={faPlus}
                                containerStyle={{justifyContent: 'flex-start'}}
                                onClick={() => navigate('/Home')}
                            />
                        </div>
        
                        <div className="CalendarContainer">
                            <CustomCalendar inspections={inspections} onClick={handleSelectedClick}/>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    )
}