import './assets/styles/global.css'
import AppRoutes from './routes'
import { RegisterProvider } from './contexts/RegisterContext'
import { GlobalProvider } from './contexts/GlobalContext'

import './app.css'

function App() {
  //deleteJsonData('user')

  return (
    <GlobalProvider>
      <RegisterProvider>

        <div className="App">
          <AppRoutes/>
        </div>

      </RegisterProvider>
    </GlobalProvider>
  );
}

export default App;
