import 'react-big-calendar/lib/css/react-big-calendar.css'; // npm install react-big-calendar moment
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { formatTimestamp, getDeadline } from '../DatePicker';
import './styles.css'

const localizer = momentLocalizer(moment)

export default function CustomCalendar({inspections, onClick, width='100%', height='100%', week=true}: any){
    const events = inspections.map((item: any, index: number) => {
        const endDate = formatTimestamp(item.deadline, true, true)

        return {
            id: index, 
            title: item.title, 
            start: endDate, 
            end: endDate
        }
    })

    const eventPropGetter = (event: any) => {
        const isSelected = false;

        const style = {
            backgroundColor: isSelected ? '#f24000' : '#F36934',
            color: isSelected ? 'black' : 'white',
        }

        return {style}
    }
  
    return (
      <div style={{ width: width, height: height }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="month"
          titleAccessor="title"
          views={week ? ['month', 'week'] : ['month']}
          onSelectEvent={(event: any) => onClick(event)}
          eventPropGetter={eventPropGetter}
          messages={{
            today: 'Hoje',
            next: 'Próximo',
            previous: 'Anterior',
            month: 'Mês',
            week: 'Semana',
            day: 'Dia'
          }}
          step={180} 
          timeslots={1}
          style={{ width: '100%', height: '100%', color: 'white', opacity: .9 }}
        />
      </div>
    )
}