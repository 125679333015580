const translations: any = {
    reports: {
        PT: 'Relatórios de Inspeção',
        US: 'Inspection Reports',
        ES: 'Informes de Inspección'
    },

    newInspection: {
        PT: 'Solicitar nova inspeção',
        US: 'Request new inspection',
        ES: 'Solicitar nueva inspección'
    },

    requestedInspection: {
        PT: 'Inspeções solicitadas',
        US: 'Requested Inspections',
        ES: 'Inspecciones Solicitadas'
    },

    inspectionCalendar: {
        PT: 'Agenda de Inspeções',
        US: 'Inspection Schedule',
        ES: 'Calendario de Inspecciones'
    },

    lastInspections: {
        PT: 'Últimas Inspeções',
        US: 'Last Inspections',
        ES: 'Últimas Inspecciones'
    },

    myRequests: {
        PT: 'Minhas Solicitações',
        US: 'My Requests',
        ES: 'Mis Solicitudes'
    },

    inspSync: {
        PT: 'Sincronismo dos Inspetores',
        US: 'Inspectors Syncronism',
        ES: 'Sincronismo de los Inspectores'
    },

    syncedTo: {
        PT: 'sincronizado em: ',
        US: 'synced to: ',
        ES: 'sincronizado a: '
    }
}

export default translations;
