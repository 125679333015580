const lang: any = {
    registerCompany: {
        PT: 'Cadastrar Nova Empresa',
        US: 'Register New Company',
        ES: 'Registrar Nueva Empresa'
    },

    editCompany: {
        PT: 'Editar Empresa',
        US: 'Edit Company',
        ES: 'Editar Empresa'
    },

    companyInfo: {
        PT: 'INFORMAÇÕES DA EMPRESA',
        US: 'COMPANY INFO',
        ES: 'INFORMACIÓN DE LA EMPRESA'
    },

    name: {
        PT: 'Nome',
        US: 'Name',
        ES: 'Nombre'
    },

    occupationArea: {
        PT: 'Área de Atuação',
        US: 'Occupation Area',
        ES: 'Área de Actuación'
    },

    description: {
        PT: 'Descrição',
        US: 'Description',
        ES: 'Descripción'
    },

    companyLogo: {
        PT: 'Logo da Empresa',
        US: 'Company Logo',
        ES: 'Logo de la Empresa'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    alreadyRegistered: {
        PT: 'Empresa já cadastrada',
        US: 'Company Already Registered',
        ES: 'Empresa Ya Registrada'
    },

    addError: {
        PT: 'Erro ao adicionar',
        US: 'Error on add',
        ES: 'Error al agregar'
    }
}

export default lang;
