import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles.css';

import logoImage from '../../assets/img/logo.png';
import mapImage from '../../assets/img/img_map.png';
import appImage from '../../assets/img/img_appstore.png';
import playImage from '../../assets/img/img_playstore.png';
import { downloadApk, editDatabaseByCondition, editDatabaseRowsByColumn, fetchApiData, getDataBaseCondition, postApiData, postWebData } from '../../utils/database';
import { deleteJsonData, getStoredJson, parsePassword, randomNumericString, saveJsonData, sleep, useInitialCheck } from '../../utils';
import { useGlobalContext } from '../../contexts/GlobalContext';
import lang from './lang';
import CustomModal from '../../components/Modal';
import CustomTextField from '../../components/CustomTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';


export function SignInPage() {
	const [email, setEmail] = useState<string>('')
	const [psswd, setPsswd] = useState<string>('')
	const [psswd2, setPsswd2] = useState<string>('')
	const [loadingBar, setLoadingBar] = useState(false)
	const [logged, setLogged] = useState<null | boolean>(null)
	const [msg, setMsg] = useState({ active: false, content: 'Senha Incorreta'})

	const [code, setCode] = useState<string>('')
	const [expectedCode, setExpectedCode] = useState<any>({code: '', timestamp: null})

	const [alertTitle, setAlertTitle] = useState<string>('')

	const [forgotModal, setForgotModal] = useState<boolean>(false)
	const [newPassModal, setNewPassModal] = useState<boolean>(false)

	const navigate = useNavigate()
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language
	useInitialCheck(initialCheck)

	async function initialCheck() {
		await sleep(2000)
		const user = getStoredJson('user')

		if (!user)
			return setLogged(false)

		setLogged(null)
		await sleep(2000)
		setLogged(true)
		navigate('/Home')
	}

	async function validateAccount(userData: any) {
		if (email.trim() != userData.email)
			return 1

		if (psswd.trim() != userData.password)
			return 1

		if (userData.category == "inspector") {
			return 2
		}

		return 0
	}

	async function writeMsg(text: string) {
		await sleep(2000)
		setMsg({ active: true, content: text })
		setLoadingBar(false)
		await sleep(2000)
		setMsg({ active: false, content: '' })
	}

	async function handleLogin() {
		setLoadingBar(true)
		const response = await getDataBaseCondition('users', 'email', email)

		//alert(JSON.stringify(response))

		if (email.trim() == '' || psswd.trim() == '')
			return await writeMsg(lang.incompleteData[currLang])

		if (!response || response.status == 'error')
			return await writeMsg(lang.dbError[currLang])

		if (response.length == 0)
			return await writeMsg(lang.userNotFound[currLang])

		const valid = await validateAccount(response[0])

		if (valid == 0) {
			saveJsonData('user', { ...response[0], timestamp: Date.now() })
			setLoadingBar(false)

			setLogged(null)
			await sleep(2000)
			setLogged(true)
			return navigate('/Home')
		}

		if (valid == 1)
			await writeMsg(lang.incorrectPassword[currLang])
		else if (valid == 2)
			await writeMsg(lang.accessDenied[currLang])
	}

	async function handleSendMail(){
		setLoadingBar(true)
		await sleep(1000)

		const newCode = randomNumericString(4)
		setExpectedCode({code: newCode, timestamp: new Date()})

		const data = {
			destinyMail: email.trim(),
			subject: lang.confirmationPassword[currLang],
			message: lang.codeConfirmation[currLang] + newCode
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		setAlertTitle(lang.min[currLang])
		setLoadingBar(false)
	}

	async function handleConfirm(){
		await sleep(500)

		if(!expectedCode.timestamp)
			return setAlertTitle('envie o código de confirmação')

		const codeTime = (expectedCode.timestamp).getTime()
		const currentTime = (new Date()).getTime()

		if(currentTime - codeTime > 5*60*1000){
			alert(lang.timeout[currLang])
			setExpectedCode({code: '', timestamp: null})
			return setForgotModal(false)
		}

		if(code.trim().length < 4)
			return setAlertTitle(lang.invalidCode[currLang])

		if(expectedCode.code.length < 4)
			return setAlertTitle(lang.invalidCode[currLang])

		if(code.trim() != expectedCode.code)
			return setAlertTitle(lang.invalidCode[currLang])

		setPsswd('')
		setForgotModal(false)
		setNewPassModal(true)
	}

	async function handleChangePassword(){
		if(psswd.length < 5 || psswd2.length < 5)
			return alert(lang.shortPassword[currLang])

		if(psswd.trim() != psswd2.trim())
			return alert(lang.equalPass[currLang])
	  
		const data = {
			email: email.trim(),
			password: psswd.trim()
		}

		const response = await editDatabaseByCondition('users', 'email', data.email, data)

		if(!response || response.status != 'success')
			return alert(lang.bdError[currLang])

		return window.location.reload()
	}

	function ModalLogin(){

		return (
			<div style={{display: 'flex', flexDirection: 'column', width: '70%', flex: 1}}>
				<div style={{color: 'gray', fontSize: '1.5rem'}}>
					Find Your Account
				</div>

				<div style={{flex: .2}}/>
				
				<div style={{color: 'gray', fontSize: '1rem'}}>
					{lang.confirmationMail[currLang]}
				</div>

				<div style={{padding: '5px'}}/>

				<input
					className='inputForgot'
					placeholder={lang.type[currLang]}
					value={email}
					onChange={(event) => setEmail(event.target.value)}
				/>
				
				<div
					className='btnEntrarLogin' 
					onClick={async () => await handleSendMail()}
					style={{height: '2.5rem'}}
				>
					{
						loadingBar ? 
						(<div className='loading-circle' style={{ zIndex: loadingBar ? 0 : -1 }} />) : 
						(<p>{lang.send[currLang]}</p>)
					}
				</div>

				<div style={{flex: .2}}/>

				<div style={{color: 'gray', fontSize: '1rem'}}>
					{lang.confirmationCode[currLang]}
				</div>

				<div style={{padding: '5px'}}/>
				
				<input
					className='inputForgot'
					placeholder={lang.type[currLang]}
					value={code}
					onChange={(event) => setCode(event.target.value)}
				/>

				<div 
					className='btnEntrarLogin' 
					onClick={async () => await handleConfirm()}
					style={{height: '2.5rem'}}
				>
					{
						loadingBar ? 
						(<div className='loading-circle' style={{ zIndex: loadingBar ? 0 : -1 }} />) : 
						(<p>{lang.confirm[currLang]}</p>)
					}
				</div>

				<div style={{flex: .2}}/>

				<div style={{color: 'red', fontSize: '1rem', height: '1rem'}}>
					{alertTitle}
				</div>
			</div>
		)
	}
	

	function ModalPass(){
		return (
			<div style={{display: 'flex', flexDirection: 'column', width: '70%', flex: 1}}>
				<div style={{flex: .2}}/>

				<div style={{color: 'gray', fontSize: '1rem'}}>
					{lang.newPassword[currLang]}
				</div>

				<div style={{padding: '5px'}}/>

				<input
					className='inputForgot'
					placeholder={lang.type[currLang]}
					value={psswd}
					onChange={(event) => setPsswd(event.target.value)}
				/>

				<div style={{flex: .1}}/>

				<div style={{color: 'gray', fontSize: '1rem'}}>
					{lang.repeatNewPassword[currLang]}
				</div>

				<div style={{padding: '5px'}}/>

				<input
					className='inputForgot'
					placeholder={lang.type[currLang]}
					value={psswd2}
					onChange={(event) => setPsswd2(event.target.value)}
				/>

				<div style={{flex: .2}}/>

				<div 
					className='btnEntrarLogin' 
					onClick={async () => {
						setLoadingBar(true)
						await sleep(1000)
						await handleChangePassword()
						setLoadingBar(false)
					}}
					style={{height: '2.5rem'}}
				>
					{
						loadingBar ? 
						(<div className='loading-circle' style={{ zIndex: loadingBar ? 0 : -1 }} />) : 
						(<p>{lang.confirm[currLang]}</p>)
					}
				</div>
			</div>
		)
	}


	if (logged === null)
		return (
			<div className="SignIn" style={{ display: 'flex', flexDirection: 'column' }}>
				<img className="logoImageBig" src={logoImage} alt="Map" />
				<div style={{ padding: '2rem' }} />
				<div className='loading-circle-splash' />
			</div>
		)

	return (
		<div className="SignIn">
			<CustomModal modalOpen={forgotModal} className='forgotPasswordContainer' interfere={false}>
				<div 
					style={{display: 'flex', width: '100%', justifyContent: 'flex-end', cursor: 'pointer'}}
					onClick={() => setForgotModal(false)}
				>
					<FontAwesomeIcon icon={faClose} color='gray' fontSize={'1.2rem'}/>
				</div>

				{ModalLogin()}
			</CustomModal>

			<CustomModal modalOpen={newPassModal} className='forgotPasswordContainer' interfere={false}>
				<div 
					style={{display: 'flex', width: '100%', justifyContent: 'flex-end', cursor: 'pointer'}}
					onClick={() => setNewPassModal(false)}
				>
					<FontAwesomeIcon icon={faClose} color='gray' fontSize={'1.2rem'}/>
				</div>

				{ModalPass()}
			</CustomModal>

			<div className="content2">
				<div className="appimages">
					<img className="mapImage" src={mapImage} alt="Map" />

					<div className="downloadImages" onClick={downloadApk}>
						<img className="appstoreImage" src={appImage} alt="AppStore" />
						<img className="playstoreImage" src={playImage} alt="PlayStore" />
					</div>
				</div>

				<div className="form-container2">
					<img className="logo" src={logoImage} alt="Logo" />

					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<input
							className='login'
							type="text"
							placeholder="Email"
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							onKeyDown={async (event) => event.key == 'Enter' && await handleLogin()}
						/>

						<input
							className='login'
							type="password"
							placeholder={lang.password[currLang]}
							value={psswd}
							onChange={(event) => setPsswd(event.target.value)}
							onKeyDown={async (event) => event.key == 'Enter' && await handleLogin()}
						/>

						<div style={{padding: '4px'}}/>

						<div className='thirdRow' onClick={() => setForgotModal(true)}>
							<p style={{ cursor: 'pointer', color: '#666666', userSelect: 'none' }}>
								{lang.forgot[currLang]}
							</p>
						</div>
					</div>

					<div id='error-msg'>
						<p style={{ color: msg.active ? '#F44E3F' : 'transparent' }}>{msg.content}</p>
					</div>

					<div className='btnEntrarLogin' onClick={async () => await handleLogin()}>
						{
							loadingBar ? 
							(<div className='loading-circle' style={{ zIndex: loadingBar ? 0 : -1 }} />) : 
							(<p>{lang.enter[currLang]}</p>)
						}
					</div>
				</div>
						
				<div className="appimages2">
					<img className="mapImage" src={mapImage} alt="Map" />
					
					<div className="downloadImages" onClick={downloadApk}>
						<img className="appstoreImage" src={appImage} alt="AppStore" />
						<img className="playstoreImage" src={playImage} alt="PlayStore" />
					</div>
				</div>
			</div>
		</div>
	);
}