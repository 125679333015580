import React, { useState, useEffect } from 'react';
import useViacep from "@rsiqueira/use-viacep";
import './styles.css';
import { getStoredJson, saveJsonData, useInitialCheck } from '../../utils';
import { getDatabaseRows } from '../../utils/database';

export function SearchBox({ title, dict, value, setValue }: any) {
  const [results, setResults] = useState<any>([]);
  const [isActive, setIsActive] = useState<any>(false);
  const [dataCep, setDataCep] = useState('');
  const { cep } = useViacep(dataCep);
  const [loc, setLoc] = useState<any>([]);
  const [hasLoc, setHasLoc] = useState(false)
  const [show, setShow] = useState(true)
  const [target, setTarget] = useState<string>('company')
  const [allOptions, setAllOptions] = useState<any>([null])
  useInitialCheck(importInfo)

  async function importInfo(){
    let allData = []
    let search  = ''

    allData = await getDatabaseRows('plants')
    setTarget(search)
    setAllOptions(allData)

    if(window.location.pathname === '/Plant'){
      setShow(false)
    }
}

  async function fetchPlaces(searchQuery: any){
    try {
      const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchQuery)}.json?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`);
      const data = await response.json();

      if (data.features.length == 0) {
        setDataCep(value);
      } else if (data && data.features) {
        if(value.length > 0){
            const hasValue = allOptions.filter((data: any) => data.name.toLowerCase().includes(value.toLowerCase()));
            if(hasValue.length > 0){
              setLoc(hasValue)
              setHasLoc(true)
              //return
            }
        }
        //console.log("DATAFEATURES")
        //console.log(data.features)
        setResults(data.features);
      } else {
        setResults([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setResults([]);
    }
  };
  
  async function updateCep(){
    let parts = [];
    if (cep.logradouro) parts.push(cep.logradouro);
    if (cep.localidade) parts.push(cep.localidade);
    let resultCep = parts.join(" ");
    //console.log(resultCep)
    await fetchPlaces(resultCep);
  }

  useInitialCheck(updateCep, [cep])

  useEffect(() => {
    if (value.length > 0) {
      const delayDebounce = setTimeout(() => {
        fetchPlaces(value);
      }, 500);

      return () => clearTimeout(delayDebounce);
    } else {
      setIsActive(false);
      setHasLoc(false);
    }
  }, [value]);

  const handleOptionClick = (option: any) => {
    setIsActive(false);
    setValue({
      ...dict,
      location: option.place_name,
      lat: option.geometry?.coordinates[1],
      long: option.geometry?.coordinates[0]
    });
  };

  const handleOptionClickMod = (option: any) => {
    setIsActive(false);
    setHasLoc(false);
    setValue({
      location: option.location,
      lat: option.lat,
      long: option.long
    });
  };

  function handleChangeValue(e: any){
    const text = e.target.value
    setIsActive(true);
    setValue({...dict, location: text})
  }

  return (
    <div>
      <div className={`search ${isActive ? 'active' : ''}`}>
        <input 
          type="text" 
          className='search'
          value={value} 
          onChange={handleChangeValue}
          placeholder={title} 
        />
        {isActive && (
          
          <div className="option1">
            <>
            {hasLoc && show && (
              <>
              <p style={{marginTop: '-0.3px', marginBottom: '-0.8px', marginLeft: '12px', marginRight: '10px', fontSize: '11px', color: '#F36934'}}>Plantas encontradas:</p>
              {loc.map((result: any) => (
                <div key={result.id} onClick={() => handleOptionClickMod(result)}>{result.name}</div>
              ))}
              <p style={{marginTop: '-0.1px', marginBottom: '-1.2px', marginLeft: '12px', marginRight: '10px', fontSize: '11px', color: '#F36934'}}>Localizações encontradas:</p>
              </>
              
            )
            }
            
            {results.map((result: any) => (
            <div key={result.id} onClick={() => handleOptionClick(result)}>{result.place_name}</div>
            ))}
            </>
          </div>
        )}
        
      </div>
    </div>
  );
}
