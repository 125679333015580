import { ChangeEvent, useRef, useState } from 'react';
import { faAngleLeft, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';

import './styles.css';

import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import { getStoredJson, useInitialCheck } from '../../../utils/index';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, getDataBaseCondition, getDatabaseRows } from '../../../utils/database';
import CustomDropBox from '../../../components/CustomDropBox';
import CustomTextField from '../../../components/CustomTextField';
import lang from './lang';
import AsyncButton from '../../../components/AsyncButton';


export function ClientPage(){
  const {globalOptions, setGlobalOptions} = useGlobalContext()
  const currLang = globalOptions.language
  const navigate = useNavigate();

  const [selectedClient, setSelectedClient] = useState<any>({})
  const [companies, setCompanies] = useState<any>([])
  const [emailMsg, setEmailMsg] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [edit, setEdit] = useState(false)
  useInitialCheck(importCompanies)
  useInitialCheck(initialCheck, [window.location.href, companies])
  const user = getStoredJson('user')

  const categories= [
    {value: "manager",   name: lang.manager[currLang] },
    {value: "auxiliary", name: lang.auxiliary[currLang]},
    {value: "admin",     name: lang.admin[currLang] }
  ]

  function getPermission(read: boolean, edit: boolean, register: boolean){
    if(read && edit && !register)
        return 'ra'

    if(read && !edit && !register)
        return 'r'

    if(edit && !read && !register)
        return 'a'

    if(register && !read && !edit)
        return 'c'

    if(read && register && !edit)
        return 'rc'

    if(edit && register && !read)
        return 'ac'

    if(read && edit && register)
        return 'rac'

    return 'none'
}

  function sendPermission(category: string){
    let permissions: any
    let preNewData: any = {}

    if(category == "manager"){
      permissions = [
        {id: 'ger_inspectors', read: true, edit: true, register: true},
        {id: 'ger_clients', read: true, edit: true, register: true},
        {id: 'ger_plants', read: true, edit: true, register: true},
        {id: 'ger_processes', read: true, edit: true, register: true},
        {id: 'ger_equipments', read: true, edit: true, register: true},
        {id: 'ger_companies', read: true, edit: true, register: false},
        {id: 'ger_reports', read: true, edit: true, register: true}
      ]
    }else if(category == "admin"){
      permissions = [
        {id: 'ger_inspectors', read: true, edit: true, register: false},
        {id: 'ger_clients', read: true, edit: true, register: false},
        {id: 'ger_plants', read: true, edit: true, register: false},
        {id: 'ger_processes', read: true, edit: true, register: false},
        {id: 'ger_equipments', read: true, edit: true, register: false},
        {id: 'ger_companies', read: true, edit: true, register: false},
        {id: 'ger_reports', read: true, edit: true, register: false}
      ]      
    }else{
      permissions = [
        {id: 'ger_inspectors', read: true, edit: false, register: false},
        {id: 'ger_clients', read: true, edit: false, register: false},
        {id: 'ger_plants', read: true, edit: false, register: false},
        {id: 'ger_processes', read: true, edit: false, register: false},
        {id: 'ger_equipments', read: true, edit: false, register: false},
        {id: 'ger_companies', read: true, edit: false, register: false},
        {id: 'ger_reports', read: true, edit: false, register: false}
      ]
    }

    for(let permission of permissions){
      let key = permission.id
      preNewData[key] = getPermission(permission.read, permission.edit, permission.register)
    } 
  
    let newData: any = JSON.stringify(preNewData)
    
    return newData
  }

  async function importCompanies() {
		let data = []

		if(user.company == '123456789/0001')
			data = await getDatabaseRows('companies')
		else
			data = await getDataBaseCondition('companies', 'cnpj', user.company)

		setCompanies(data)
	}

  async function initialCheck(){
    const currentUrl = window.location.href
    const isEdit = currentUrl.indexOf('?edit') != -1 
    setEdit(isEdit)
    
    if(!isEdit)
      return
    
    let editData = getStoredJson('edit')
    const target = companies.filter((item: any) => editData.company == item.cnpj)

    if(target.length > 0)
      editData.company = target[0]

    setSelectedClient(editData)
  }

  async function handleEdit(){
    const edit = getStoredJson('edit')
  
    if(!validator.isEmail(selectedClient.email))
      return setEmailMsg(true)
    
    const newData = {
      name: selectedClient.name,
      email: selectedClient.email,
      password: selectedClient.password,
      company: selectedClient.company.cnpj,
      category: selectedClient.category,
      permission: sendPermission(selectedClient.category)
    }

    const response = await editDatabaseByCondition('users', 'name', edit.name, newData)

    if(response.status != 'success')
      return alert('erro ao se conectar com o servidor')
    
    navigate('/Home')
  }

  function handleNameChange(text: string){
    setSelectedClient({...selectedClient, name: text})
  }

  function handleEmailChange(text: string){
    setSelectedClient({...selectedClient, email: text})
  }

  function handlePasswordChange(text: string){
    setSelectedClient({...selectedClient, password: text})
  }

  function handleCompanyChange(selectedOption: any){
    if(selectedOption.key && selectedOption.key == 'default')
      return navigate('/Company?insert')

    setSelectedClient({...selectedClient, company: selectedOption})
  }

  function handleCategoryChange(selectedOption: any){
    if(selectedOption.key && selectedOption.key == 'default')
      return navigate('/Company?insert')

    setSelectedClient({...selectedClient, category: selectedOption.value})
  }

  async function handleSubmit(){ 
    if(!validator.isEmail(selectedClient.email))
      return setEmailMsg(true)
  
    const clientData = {
      company_id: 1,
      name: selectedClient.name,
      email: selectedClient.email,
      password: selectedClient.password,
      company: selectedClient.company.cnpj,
      category: selectedClient.category,
      permission: sendPermission(selectedClient.category)
    }
    
    const checkUnique = await getDataBaseCondition('users', 'name', clientData.name) 

    if(checkUnique.length > 0)
        return alert('usuário já cadastrado')

    const response = await addDataBaseRow('users', clientData)

    if(response.status != 'success') 
      return alert('erro ao se comunicar com o servidor')

    navigate(-1) 
  }

  function handleManagement(){
    setGlobalOptions({...globalOptions, activeButton: 'Client'})
    navigate('/Managment')
  } 

  function isFieldsEmpty(){
    const data = [
      selectedClient?.email,
      selectedClient?.name,
      selectedClient?.password,
      selectedClient?.category,
      selectedClient?.company?.name,
    ]

    for(let value of data){
      if(!value)
        return true

      if(value.trim().length == 0)
        return true
    }

    return false
  }

  return (
    <div className="MainContainer">
      
      <div className='SideBarCompensation'/>

      <div className='CentralBlock' style={{height: '100vh'}}>
        <FlagSelector/>
        <Sidebar activePage='Register'/>

        <div className="RegisterMainContainer">
          <div className="iconContainer" style={{width: '100%'}}>
              <div
                className='iconLeft' 
                style={{alignSelf: 'center', width: '7%'}}
                onClick={() => navigate(-1)}
                >
                
                <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradao"/> 
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', width: '85%'}}>
                <p className='clientText'>
                  {edit ? lang.editClient[currLang] : lang.registerClient[currLang]}
                </p>
              
                <div className='folderContainer' onClick={handleManagement}>
                  <FontAwesomeIcon icon={faFolder} title="Clientes cadastrados"/>        
                </div>
              </div>
          </div>
  
          <div style={{width: '85%'}}>
            <text className='equipmentText2'>{lang.userInfo[currLang]}</text>
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField 
              placeholder={lang.name[currLang]}
              value={selectedClient.name ? selectedClient.name : ''} 
              setValue={handleNameChange}
            />
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField 
              placeholder='Email' 
              value={selectedClient.email ? selectedClient.email : ''} 
              setValue={handleEmailChange}
            />
            {emailMsg === true && <p style={{ color: 'red', marginBottom: '-0.5rem', paddingLeft: '0.5rem' }}>Email inválido!</p>}
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField 
              placeholder={lang.password[currLang]}
              value={selectedClient.password ? selectedClient.password : ''} 
              setValue={handlePasswordChange}
            />
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <CustomDropBox
              options={companies}
              value={selectedClient.company ? selectedClient.company.name : ''}
              setValue={handleCompanyChange}
              placeholder={lang.company[currLang]}
              key='name'
              callbackKey={lang.addNew[currLang]}
            /> 
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <CustomDropBox
              options={categories}
              value={selectedClient.category ? lang[selectedClient.category][currLang] : ''}
              setValue={handleCategoryChange}
              placeholder={lang.category[currLang]}
              key='name'
            /> 
          </div>

          <div style={{padding: '.6rem'}}/>

          <AsyncButton
						active={loading || isFieldsEmpty()}
						text={edit ? lang.edit[currLang] : lang.register[currLang]}
						className='button-client'
						onClick={
							edit 
							? 
							async () => {setLoading(true); await handleEdit(); setLoading(false)}
							:
							async () => {setLoading(true); await handleSubmit(); setLoading(false)}
						}
						loading={loading}
					/>
        </div>
      </div>
    </div>
  );
}