import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../contexts/GlobalContext'
import './styles.css'
import lang from './lang'
import FlagSelector from '../../../components/FlagSelector'
import { url } from '../../../utils/database'

export function DeleteAccount() {
    const [email, setEmail] = useState('')
    const [hasSentRequest, setHasSentRequest] = useState(false)

    const { globalOptions } = useGlobalContext()
	const currLang = globalOptions.language
    const navigate = useNavigate()

    async function requestAccountDeletion() {
        let userLang = currLang.toLowerCase()
        if (userLang == 'US')
            userLang = 'en'

        try {
            const response = await fetch(`${url}requestAccountDeletion`, {
                method: 'POST',
                body: JSON.stringify({ 
                    email: email,
                    subject: lang.subject[currLang],
                    lang: userLang
                })
            })

            if (!response.ok) {
                const responseJson = (await response.json())
                if (responseJson !== undefined)
                    throw new Error(responseJson.message)
                else
                    throw new Error("Couldn't communicate to server")
            }

            setHasSentRequest(true)
        }
        catch (error) {
            const e = error as Error
            alert(e.message)
        }
    }

    return (
        <div id="container">
            <div>
                <FlagSelector onlyLang/>
            </div>

            <div id="content">
                <p id="title">{lang.title[currLang]}</p>
                <p id="text">
                    {
                         lang.text[currLang].split('<br/>').map((line: string, index: number) => (
                            <Fragment key={index}>
                                {line}
                                <br/>
                                <br/>
                            </Fragment>
                        ))
                    }
                </p>

                <p className='focus-text'>{lang.typeEmail[currLang]}</p>
                <input type='text' onChange={e => setEmail(e.target.value)}/>

                <button type='submit' onClick={requestAccountDeletion}>
                    {lang.send[currLang]}
                </button>
            </div>
            
            {
                hasSentRequest &&
                <div id="modal-container">
                    <div id="modal-content">
                        <h1>{lang.modalTitle[currLang]}</h1>
                        <p>{lang.modalText[currLang]}</p>
                        
                        <button 
                            id="modal-footer"
                            onClick={() => {
                                setHasSentRequest(false)
                                navigate('/')
                            }}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}