import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function handleFileChange(event: React.ChangeEvent<HTMLInputElement>, setFileData: any) {
	if (event.target.files) {
		const filesArray = Array.from(event.target.files)
		setFileData(filesArray);
	}
}

export function FileDocumentUpload({ fileData, setFileData, title }: any) {
	return (
		<div className="parent">
			<div className='file-upload'>
				<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '1.5rem' }} />
				<p>{title}</p>

				<input
					type="file"
					onChange={(event: any) => handleFileChange(event, setFileData)}
					multiple
					style={{
						opacity: 0,
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						cursor: 'pointer'
					}}
				/>

				{fileData && fileData.map((file: any, index: number) => (
					<p key={index} className="image-name-preview">Nome do Arquivo: {file.name}</p>
				))}
			</div>
		</div>
	);
}
