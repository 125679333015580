import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'

const CustomButton = ({title, icon, containerStyle={}, textStyle={}, iconStyle={}, onClick=undefined}: any) => {

    return (
    <div className='customButton' style={containerStyle} onClick={onClick}>
        <FontAwesomeIcon icon={icon} className='customButtonIcon' style={iconStyle}/>

        <p className='customButtonText' style={textStyle}>{title}</p>
    </div>
    )
}

export default CustomButton