import { Fragment } from 'react'

import { useGlobalContext } from '../../../contexts/GlobalContext'
import './styles.css'
import lang from './lang'
import FlagSelector from '../../../components/FlagSelector'

export function Policy() {
    const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language

    return (
        <div id="container">
            <div>
                <FlagSelector onlyLang/>
            </div>

            <div id="content">
                <p id="title">{lang.title[currLang]}</p>
                <p id="policy-text">
                   {
                        lang.text[currLang].split('<br/>').map((line: string, index: number) => (
                            <Fragment key={index}>
                                {line}
                                <br/>
                                <br/>
                            </Fragment>
                        ))
                   }
                </p>
            </div>
        </div>
    )
}